import type { App } from 'vue';
import {
  TRACKER_MODULE_TYPES,
  type IdentifyProps,
  type IdentifyPropsWithCompany,
  type TrackerModule,
} from '../types';

export class GoogleAnalyticsTracker implements TrackerModule {
  moduleType = TRACKER_MODULE_TYPES.GOOGLE_ANALYTICS;
  constructor(googleAnalyticsId: string) {
    this.insertGoogleAnalyticsTag(googleAnalyticsId);
  }
  init(app: App<any>): void {
    // do nothing
  }
  identifyWithCompany({ id, name, email, company }: IdentifyPropsWithCompany): void {
    // do nothing
  }
  view(): void {
    // do nothing
  }
  track(event: string, properties: any) {
    // do nothing
  }
  openChat(): void {
    // do nothing
  }
  identify({ id, name, email, intercomHash }: IdentifyProps): void {
    window.gtag?.('event', 'identify', {
      user_id: id,
    });
  }

  private insertGoogleAnalyticsTag(googleAnalyticsId: string) {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    document.head.appendChild(script);

    // gtag の実体が読み込み終わるまでの mock 的な関数
    // https://developers.google.com/tag-platform/gtagjs/configure?hl=ja
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag(...args: any[]) {
      window.dataLayer!.push;
    };

    window.gtag('js', new Date());
  }
}
