import { GlobalConfig } from '@cdm/configs';

export type Fetcher = (
  path: string,
  requestData: Record<string, unknown>,
  query: Record<string, string>,
  options: Record<string, any>,
) => Promise<any>;

type ErrorResponse = {
  error_code: string;
  error_message: string;
};

export class ApiError extends Error {
  message: string;
  status: number;
  error_code?: string;
  cause?: Error | null;
  constructor(message: string, status: number, options?: { error_code?: string; cause?: Error }) {
    super(message);
    this.status = status;
    this.message = message;
    if (options?.error_code) this.error_code = options.error_code;
    if (options?.cause) this.cause = options.cause;
  }
}

export const createFetcher = ({
  getToken,
}: {
  getToken?: (workspaceId?: string) => Promise<string>;
} = {}): Fetcher => {
  return async (
    path: string,
    requestData: Record<string, unknown> = {},
    query: Record<string, string> = {},
    options: Record<string, any> = {},
  ) => {
    const url = getUrl(path, query);

    const reqOpts: RequestInit = {
      method: options.useGet ? 'GET' : 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: options.useGet ? undefined : JSON.stringify(requestData),
    };

    if (getToken) {
      const token = await getToken(query.ws ?? undefined);
      // @ts-expect-error
      reqOpts.headers['Authorization'] = `Bearer ${token}`;
    }

    let response;
    try {
      response = await fetch(url, reqOpts);
    } catch (_err) {
      const err = new ApiError('unresolved err', 0, { cause: _err as Error });
      throw err;
    }

    if (options.useRawResponse) {
      return response;
    }

    if (response.status == 200) {
      const data = await response.json();
      return data;
    }

    await handleErrorResponse(response);
  };
};

export const handleErrorResponse = async (response: Response): Promise<void> => {
  let err: Error;
  try {
    const resp = response.clone();
    const errResp = await resp.json();
    const { error_code, error_message } = errResp as ErrorResponse;
    err = new ApiError(error_message, resp.status, { error_code });
  } catch (_) {
    const msg = await response.text();
    err = new ApiError(msg, response.status);
  }
  throw err;
};

const getUrl = (path: string, query: Record<string, string> = {}) => {
  const url = new URL(path, `${GlobalConfig.ACCOUNT_URL}/`);
  url.search = new URLSearchParams(query).toString();
  return url.toString();
};

export const getAttachedFileNameByResponse = (response: Response): string | null => {
  const contentDisposition = response.headers.get('content-disposition');
  if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return null;
};

export const streamDownloadByResponse = async (
  response: Response,
  attachedFileName: string,
  options: { copyToClipboard?: boolean } = {},
) => {
  if (!response.body) {
    throw new Error('ReadableStream not supported in this browser.');
  }
  const reader = response.body.getReader();
  const stream = new ReadableStream({
    start(controller) {
      function push() {
        reader.read().then(({ done, value }) => {
          if (done) {
            controller.close();
            return;
          }
          controller.enqueue(value);
          push();
        });
      }
      push();
    },
  });
  const newResponse = new Response(stream);
  const blob = await newResponse.blob();
  if (options.copyToClipboard) {
    const text = await blob.text();
    if (!document.hasFocus()) {
      // DOMException: Document is not focused が出るので、もう少し分かりやすいエラーメッセージを出す
      throw new Error('Please keep the browser focused to copy to the clipboard.');
    }
    await navigator.clipboard.writeText(text);
    return;
  }
  const blobURL = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = blobURL;
  a.download = attachedFileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(blobURL);
};
